<template>
  <footer>
    <!-- footer content begin -->
    <div class="uk-section">
      <div class="uk-container uk-margin-top">
        <div class="uk-grid">
          <div class="uk-width-2-3@m">
            <div
              class="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid"
              data-uk-grid=""
            >
              <div class="uk-first-column">
                <h5>Site</h5>
                <ul class="uk-list uk-link-text">
                  <li><router-link :to="{ name: 'guest.privacy-policy' }">Privacy Policy </router-link></li>
                  <li><router-link :to="{ name: 'guest.dcma' }">Do Not Sell My Info</router-link></li>
                  <li><router-link :to="{ name: 'guest.terms-of-use' }">Terms of Use</router-link></li>
                </ul>
              </div>
              <div>
                <h5>About</h5>
                <ul class="uk-list uk-link-text">
                  <li><router-link :to="{ name: 'guest.about-us' }" class="text-base">Overview </router-link></li>
                  <li><a href="https://myforexglobal.com/blog" target="_blank" class="text-base">Blog</a></li>
                  <li><router-link :to="{ name: 'guest.contact-us' }" class="text-base">Contact Us</router-link></li>
                </ul>
              </div>
              <div class="in-margin-top-60@s">
                <h5>Learning</h5>
                <ul class="uk-list uk-link-text">
                  <li><router-link :to="{ name: 'guest.academy' }">First course</router-link></li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCtLrduu1x1kuwbD9S16PZmg/featured"
                      target="_blank"
                    >
                      Free Learning Videos
                    </a>
                  </li>
                  <li><a href="https://tinyurl.com/yckkpwh6" target="_blank">Charting Software</a></li>
                </ul>
              </div>
            </div>
            
            <partners />
            
            <div class="uk-margin-medium-top">
              <h5 class="uk-margin-remove">Disclaimer</h5>
              <p class="uk-margin-remove" style="font-size: 13px">Trading leveraged products such as Forex and CFDs may not be suitable for all investors as they carry a high degree of risk to your capital. Please ensure that you fully understand the risks involved, taking into account your investments objectives and level of experience, before trading, and if necessary seek independent advice.</p>
              <p class="uk-margin-remove" style="font-size: 13px">Do not trade with money you cannot afford to lose. This website is neither a solicitation nor an offer to Buy/Sell Currencies, Futures or Options. No representation is being made that any account will or is likely to achieve profits or losses similar to those discussed on this website. The past performance of any trading system or methodology is not necessarily indicative of future results</p>
            </div>
          </div>
          <div class="uk-width-1-3@m uk-flex uk-flex-right@m">
            <ul class="uk-list uk-link-text in-footer-socials">
              <li>
                <h5>Join us on social</h5>
              </li>
              <li>
                <a href="https://www.facebook.com/myforexglobal/" target="_blank" class="fb-bg"
                  ><i style="margin-right: 10px;" class="fa fa-facebook"></i>Facebook</a
                >
              </li>
              <li>
                <a href="https://twitter.com/myforexglobal1" target="_blank" class="tw-bg"
                  ><i style="margin-right: 10px;" class="fa fa-twitter"></i>Twitter</a
                >
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCtLrduu1x1kuwbD9S16PZmg/featured" target="_blank" class="yt-bg"
                  ><i style="margin-right: 10px;" class="fa fa-youtube"></i>Youtube</a
                >
              </li>
              <li>
                <a href="https://www.instagram.com/myforexglobal/" target="_blank" class="ig-bg"
                  ><i style="margin-right: 10px;" class="fa fa-instagram"></i>Instagram</a
                >
              </li>
              <li>
                <a href="https://www.tiktok.com/@myforexglobal?" target="_blank" class="tl-bg"
                  ><i style="margin-right: 10px;" class="fa fa-telegram"></i>TikTok</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="uk-margin-large" />
      <div class="uk-container">
        <div class="uk-grid uk-flex uk-flex-middle">
          <div class="uk-width-2-3@m uk-text-small">
            <p class="copyright-text">
              ©2023 MyForexGlobal. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- footer content end -->
  </footer>
</template>

<script>
export default {};
</script>
