<template>
  <div>
    <div v-if="error == null">
      <app-header />

      <router-view />

      <app-footer />
    </div>
    <template v-if="error == 404">
      <error-404 />
    </template>
    <template v-if="error == 403">
      <error-403 />
    </template>
    <template v-if="error == 500">
      <error-500 />
    </template>
  </div>
</template>

<script>
import AppHeader from './guest/components/AppHeader.vue'
import AppFooter from './guest/components/AppFooter.vue'
import '@/assets/js/public'

export default {
  components: {
    AppHeader, AppFooter
  },

  mounted() {
    if (this.$store.getters.getAuthUser.role != "guest") {
      return this.$router.push({ name: "redirect" });
    }
  },

  computed: {
    error() {
      return this.$store.getters.getError;
    },
  },
};
</script>
